import { createContext, ReactNode, useState } from 'react';

type ContextType = {
	props: any;
	setProps: (data: object) => void;
};

const PagePropContext = createContext<ContextType>({
	props: {},
	setProps: () => {},
});

export const PagePropsProvider = ({ children }: { children: ReactNode }) => {
	const [props, setData] = useState({});
	const setProps = (data: object) => setData(data);
	return (
		<PagePropContext.Provider value={{ props, setProps }}>
			{children}
		</PagePropContext.Provider>
	);
};

export default PagePropContext;
