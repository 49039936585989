// import { SHOW_TOAST } from "Common/Redux/Actions/types";

import axios from 'axios'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

// import store from "Common/Redux/Reducers/Store";

// var join = require('url-join');

// var isAbsoluteURLRegex = /^(?:\w+:)\/\//;

const logoutAndRedirect = () => {
  localStorage.clear()
  if (process.env.REACT_APP_MODE === 'dev') {
    // window.open('http://localhost:3000/creator/dev-creator-login', '_self');
    alert("Not valid user, in prod it'll redirect to login")
    return
  }
  // window.location.replace(`${process.env.NEXT_PUBLIC_HOME}`);
}

export default function axiosInit() {
  // axios.defaults.baseURL = process.env.NEXT_PUBLIC_BACKEND_PATH;

  axios.interceptors.request.use(function (config) {
    let baseURL = process.env.NEXT_PUBLIC_BACKEND_PATH
    if (typeof window === 'undefined') {
      baseURL = process.env.NEXT_PUBLIC_BACKEND_PATH_PRIVATE
    } else {
      baseURL = process.env.NEXT_PUBLIC_BACKEND_PATH
    }
    config.baseURL = baseURL
    config.withCredentials = true
    if (config.headers === undefined) {
      config.headers = {}
    }
    if (typeof window === 'undefined') {
      // Available only on side
      const start = process.hrtime.bigint()
      config.headers.common['request-starttime'] = start
    }
    return config
  })

  axios.interceptors.response.use(
    function (response) {
      let start = response.headers['request-starttime']
      if (start) {
        let startAsInt = BigInt(start)
        const end = process.hrtime.bigint()
        const responseTime = Number(end - startAsInt)
        const responseTimeInMilli = responseTime / 1000000
        if (
          response.data.status === 200 &&
          process.env.NEXT_PUBLIC_MODE == 'prod' &&
          process.env.NEXT_PUBLIC_NEWRELIC_KEY
        ) {
          let name = 'nextjs.be.response.time'

          if (
            response.config.url?.includes('/common/v3.1/portfolio') &&
            response.config.method == 'post'
          ) {
            name = 'nextjs.be.portfolio.response.time'
          }
          const url = `https://metric-api.newrelic.com/metric/v1`

          const body = JSON.stringify([
            {
              metrics: [
                {
                  name,
                  type: 'gauge',
                  value: responseTimeInMilli,
                  timestamp: dayjs().utc().valueOf(),
                  attributes: {
                    environment: process.env.NEXT_PUBLIC_MODE,
                  },
                },
              ],
            },
          ])
          fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Api-Key': process.env.NEXT_PUBLIC_NEWRELIC_KEY,
            },
            body,
          })
        }
      }
      // Do something with response data
      return response
    },
    function (error) {
      //for 5xx error cases
      if (!error.response) {
        // // console.log('no response', error);
        return Promise.reject(error)
        // // console.log('axios - Internal Error');
      }
      if (error.response.status === 500) {
        // error.response = { data: { message: 'Internal Error' } };
      } else {
      }
      if (
        error.response.status === 400 &&
        error.response &&
        error.response.data &&
        (error.response.data.message === 'no such user' ||
          error.response.data.message === 'INACTIVE_OR_BANNED')
      ) {
        // localStorage.clear();
        if (process.env.NODE_ENV === 'development') {
          alert('Please login')
        } else logoutAndRedirect()
      }

      // Do something with response error
      if (
        error.response.status === 403 ||
        (error.response.status === 401 &&
          error.response.data.errorCode === 'GL105')
      ) {
        // localStorage.clear();
        if (process.env.NODE_ENV === 'development') {
          alert('Please login')
        } else logoutAndRedirect()
      }

      return Promise.reject(error)
      // // Throw errr again (may be need for some other catch)
      // return Promise.reject(error);
    }
  )
}
