import '../styles/globals.css'
import type { AppProps, NextWebVitalsMetric } from 'next/app'
import Router, { useRouter } from 'next/router'
import { Provider } from 'react-redux'

import axiosInit from '../apis/configuration'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

import { PagePropsProvider } from '../Packages/Context/PagePropsProvider'

import Header from '../components/Common/Header'
import { store } from '../Packages/store'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import WPThemeProvider from 'wp-components/dist/styled-components/ThemeContext'
import { initAmplitude } from 'wp-components/dist/analytics/Amplitude/index'

import { AnalyticsProvider } from '../Packages/Context/AnalyticsProvider'
import { useEffect } from 'react'
axiosInit()

const storeLastLink = (
  referrer?: string,
  sourceUrlParam?: string | string[]
) => {
  if (referrer && typeof referrer == 'string') {
    let data = sessionStorage.getItem('referrer')
    if (!data)
      // Get saved data from sessionStorage
      sessionStorage.setItem('referrer', referrer)
  }
  if (sourceUrlParam && typeof sourceUrlParam == 'string') {
    let data = sessionStorage.getItem('sourceUrlParam')
    if (!data)
      // Get saved data from sessionStorage
      sessionStorage.setItem('sourceUrlParam', sourceUrlParam)
  }
}

/* export function reportWebVitals({
  id,
  name,
  label,
  value,
}: NextWebVitalsMetric) {
  window.gtag("event", name, {
    event_category:
      label === "web-vital" ? "Web Vitals" : "Next.js custom metric",
    value: Math.round(name === "CLS" ? value * 1000 : value), // values must be integers
    event_label: id, // id unique to current page load
    non_interaction: true, // avoids affecting bounce rate.
  });
}
*/

const sendAnalytics = ({ name, value }: NextWebVitalsMetric) => {
  // On prod and for portfolio pages, send web-vitals/next-js metrics
  // TODO: track for custom domains?

  if (
    process.env.NEXT_PUBLIC_MODE === 'prod' &&
    Router.router?.pathname === '/[username]/[[...pagename]]' &&
    process.env.NEXT_PUBLIC_NEWRELIC_KEY
  ) {
    const url = 'https://metric-api.newrelic.com/metric/v1'

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Api-Key': process.env.NEXT_PUBLIC_NEWRELIC_KEY,
      },
      body: JSON.stringify([
        {
          metrics: [
            {
              name: `fe_nextjs_portfolio_${name.toLowerCase()}`,
              type: 'gauge',
              value,
              timestamp: dayjs().utc().valueOf(),
              attributes: { environment: process.env.NEXT_PUBLIC_MODE },
            },
          ],
        },
      ]),
    })
  }
}

export function reportWebVitals(metric: NextWebVitalsMetric) {
  switch (metric.name) {
    case 'FCP':
      // handle FCP results
      sendAnalytics(metric)
      break
    case 'LCP':
      sendAnalytics(metric)
      break
    case 'CLS':
      sendAnalytics(metric)
      break
    case 'FID':
      sendAnalytics(metric)
      break
    case 'TTFB':
      sendAnalytics(metric)
      break
    case 'Next.js-hydration':
      sendAnalytics(metric)
      break
    case 'Next.js-route-change-to-render':
      sendAnalytics(metric)
      break
    case 'Next.js-render':
      sendAnalytics(metric)
      break
    default:
      break
  }
}

function MyApp({ Component, pageProps }: AppProps) {
  const AnyComponent = Component as any
  const { asPath, pathname, query } = useRouter()
  useEffect(() => {
    // console.log('Init Amplitude')

    initAmplitude(process.env.NEXT_PUBLIC_AMPLITUDE as string)
  }, [])

  useEffect(() => {
    storeLastLink(document.referrer, query.pb_medium)
  }, [query])

  return (
    <Provider store={store}>
      {/* WPThemeProvider is providing theme related data */}
      <WPThemeProvider>
        <AnalyticsProvider>
          <PagePropsProvider>
            {/* {pathname !== '/beta-program' && <Header />} */}
            <AnyComponent {...pageProps} />
          </PagePropsProvider>
        </AnalyticsProvider>
      </WPThemeProvider>
    </Provider>
  )
}

export default MyApp

// MyApp.tsx

// import type { AppProps } from 'next/app'
// import { useRouter } from 'next/router'
// import { Provider } from 'react-redux'
// import { PagePropsProvider } from '../Packages/Context/PagePropsProvider'
// import { store } from '../Packages/store'
// import WPThemeProvider from 'wp-components/dist/styled-components/ThemeContext'
// import { initAmplitude } from 'wp-components/dist/analytics/Amplitude/index'
// import { AnalyticsProvider } from '../Packages/Context/AnalyticsProvider'
// import { useEffect } from 'react'
// import { storeLastLink } from './_app'
// import Header from '../components/Beta-landing/Header'

// function MyApp({ Component, pageProps }: AppProps) {
//   const AnyComponent = Component as any
//   const { asPath, pathname, query } = useRouter()

//   useEffect(() => {
//     initAmplitude(process.env.NEXT_PUBLIC_AMPLITUDE as string)
//   }, [])

//   useEffect(() => {
//     storeLastLink(document.referrer, query.pb_medium)
//   }, [query])

//   return (
//     <Provider store={store}>
//       <WPThemeProvider>
//         <AnalyticsProvider>
//           <PagePropsProvider>
//             {pathname !== '/beta-program' && <Header />}
//             <AnyComponent {...pageProps} />
//           </PagePropsProvider>
//         </AnalyticsProvider>
//       </WPThemeProvider>
//     </Provider>
//   )
// }
// export default MyApp
