import { createSlice, PayloadAction } from '@reduxjs/toolkit';


// Define the initial state using that type
const initialState: {

    experience: null | []
    testimonials: null | []

} = {
    experience: null,
    testimonials: null
};

export const portfolioSlice = createSlice({
    name: 'portfolio',
    initialState,
    reducers: {
        setExperience: (state, action: PayloadAction<{ experineces: any }>) => {
            state.experience = action.payload.experineces
        },
        setTestimonial: (state, action: PayloadAction<{ testimonials: any }>) => {
            state.testimonials = action.payload.testimonials
        }
    },
});

export const {
    setExperience, setTestimonial
} = portfolioSlice.actions;

export default portfolioSlice.reducer;
