import posthog, { PostHog } from 'posthog-js'
import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react'

type Events =
  | 'profile visited'
  | 'post viewed'
  | 'service viewed'
  | 'got in touch'

type Properties = {
  [key: string]: any
  post_id?: string
  service_id?: string
}
export type userId = {
  userId: string
  public_view: boolean
  portfolio_owner: boolean
  visitor_id: string | null
  visitor_role: string | null
  visitor_name: string | null
} | null

type ContextType = {
  identifyUser: (creatorId: string) => void
  captureEvent: (
    eventName: Events,
    properties: Properties,
    creatorId: string
  ) => void
}

const AnalyticsContext = createContext<ContextType>({
  identifyUser: () => {},
  captureEvent: () => {},
})

const common_capture: { [key: string]: any } = {
  'profile visited': false,
  'post viewed': [],
  'service viewed': [],
  'got in touch': false,
}

export const AnalyticsProvider = ({ children }: { children: ReactNode }) => {
  const [userId, setUserId] = useState<string | null>(null)
  const [isIdentified, setIsIdentified] = useState(false)
  const [ph, setPh] = useState<null | PostHog>(null)

  const initialisePosthog = useCallback(() => {
    // posthog.init('phc_b8GOkrRzNNt8bUnbCP44SAaMhQiDC6thxEXTjI7cHey', {
    //   api_host: 'https://analytics.passionbits.io',
    //   autocapture: false,
    //   capture_pageview: false,
    //   disable_persistence: true,
    //   loaded: (posthog: PostHog) => {
    //     setPh(posthog)
    //     // if (process.env.NODE_ENV === 'development') posthog.opt_out_capturing()
    //   },
    //   debug: true,
    // })
  }, [])

  useEffect(() => {
    // console.log(ph);
    initialisePosthog()
  }, [initialisePosthog, ph])

  const identify = useCallback(
    (id: string) => {
      ph?.identify(
        id,
        {
          role: 'Writer',
        },
        {}
      )
    },
    [ph]
  )

  const identifyUser = useCallback(
    (creatorId: string) => {
      identify(creatorId)
      setIsIdentified(true)
      setUserId(creatorId)
    },
    [identify]
  )

  const captureEvent = useCallback(
    (eventName: Events, properties: Properties, creatorId: string) => {
      if (ph) {
        if (!isIdentified) identifyUser(creatorId)

        // If event is already captured, do nothing
        if (eventName === 'profile visited') {
          if (common_capture['profile visited']) return
          common_capture['profile visited'] = true
        }

        // When user visit post
        if (eventName === 'post viewed') {
          // if he has already visited this post, do nothing
          if (common_capture['post viewed'].includes(properties.post_id)) return
          // else add post id in array, so next time user visit post we can find in array
          else common_capture['post viewed'].push(properties.post_id)
        }

        // Logic same as above
        if (eventName === 'service viewed') {
          if (common_capture['service viewed'].includes(properties.service_id))
            return
          else common_capture['service viewed'].push(properties.service_id)
        }

        ph.capture(eventName, {
          ...properties,
        })
      }
    },
    [identifyUser, ph, isIdentified]
  )

  return (
    <AnalyticsContext.Provider value={{ identifyUser, captureEvent }}>
      {children}
    </AnalyticsContext.Provider>
  )
}

export default AnalyticsContext
